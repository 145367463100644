<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <b-card @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="KnowledgeAddForm">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(updateKnowledge)"
            @reset.prevent="resetForm()"
          >
            <b-row class="align-items-end">
              <b-col
                sm="4"
                md="4">
                <TextFieldInput
                  v-model="knowledge.code"
                  :rules="{ required: false }"
                  name="Code"
                  label="Code"
                  placeholder="Input Code"
                  trim
                />
              </b-col>
              <b-col
                sm="4"
                md="4">
                <TextFieldInput
                  v-model="knowledge.subject"
                  :rules="{ required: true }"
                  name="Subject"
                  label="Subject"
                  placeholder="Input Subject"
                  trim
                />
              </b-col>
              <b-col
                sm="4"
                md="4">
                <SelectField
                  v-model="knowledge.knowledgeTypeId"
                  :options="knowledgeType"
                  name="knowledgeType"
                  form-label="Knowledge Type"
                  label-option="title"
                  placeholder="Knowledge Type"
                  :rules="{
                    required: true,
                  }"
                  return-value="id" />
              </b-col>
              <b-col
                sm="4"
                md="4">
                <TagsField
                  v-model="knowledge.tags"
                  multiple
                  name="Tags"
                  form-label="Tags"
                  label="Tags"
                />
              </b-col>
              <b-col
                sm="4"
                md="4">
                <SelectField
                  v-model="knowledge.visibelGroup"
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  multiple
                  :options="groupService"
                  name="Group"
                  form-label="Group"
                  label-option="name"
                  return-value="id"
                />
              </b-col>
            </b-row>
            <!-- <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="categoryLevel.categorySearchLvlOne"
                  :disabled="!lvlOneOptions || lvlOneOptions.length <= 0"
                  name="Category level 1"
                  form-label="Category level 1"
                  placeholder="Category level 1"
                  return-value="id"
                  :rules="{ required: true }"
                  label-option="name"
                  :options="lvlOneOptions"
                  @input="selectedItem($event, 1)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="categoryLevel.categorySearchLvlTwo"
                  :disabled="!lvlTwoOptions || lvlTwoOptions.length <= 0"
                  name="Category level 2"
                  form-label="Category level 2"
                  placeholder="Category level 2"
                  :options="lvlTwoOptions"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 2)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="categoryLevel.categorySearchLvlThree"
                  :disabled="!lvlThreeOptions || lvlThreeOptions.length <= 0"
                  name="Category level 3"
                  form-label="Category level 3"
                  placeholder="Category level 3"
                  :options="lvlThreeOptions"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 3)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="categoryLevel.categorySearchLvlFour"
                  :disabled="!lvlFourOptions || lvlFourOptions.length <= 0"
                  name="Category level 4"
                  form-label="Category level 4"
                  placeholder="Category level 4"
                  :options="lvlFourOptions"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 4)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="categoryLevel.categorySearchLvlFive"
                  :disabled="!lvlFiveOptions || lvlFiveOptions.length <= 0"
                  name="Category level 5"
                  form-label="Category level 5"
                  placeholder="Category level 5"
                  :options="lvlFiveOptions"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 5)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="categoryLevel.categorySearchLvlSix"
                  :disabled="!lvlSixOptions || lvlSixOptions.length <= 0"
                  name="Category level 6"
                  form-label="Category level 6"
                  placeholder="Category level 6"
                  :options="lvlSixOptions"
                  return-value="id"
                  label-option="name"
                  @input="selectedItem($event, 6)"
                />
              </b-col>
            </b-row> -->
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="12">
                <b-form-group label="Problem Detail">
                  <div class="my-2">
                    <PreviewSwitch
                      v-model="isProblemDetailPreview"
                    >
                      Preview
                    </PreviewSwitch>
                  </div>
                  <div
                    v-if="isProblemDetailPreview"
                    class="box-preview"
                    v-html="knowledge.problemDetail">
                  </div>
                  <wysiwyg
                    v-else
                    v-model="knowledge.problemDetail"
                    :rules="{ required: true }"
                    name="Problem Detail"
                    label="Problem Detail"
                    placeholder="Input Problem Detail"
                    trim
                  />
                </b-form-group>
                <b-button
                  v-ripple.400="'rgba(94,88,115)'"
                  style="background: #5e5873 !important"
                  class="mt-2"
                  type="button"
                  @click="openFile('problemFile')"
                >
                  Attach Problem Detail
                </b-button>
                <input
                  ref="addProblemFile"
                  class="d-none"
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*"
                  multiple
                  @change="onChangeFile($event, 'problemFile')" />
                <p class="mt-2 text-danger">
                  สามารถอัปโหลดได้ไม่เกิน 25MB
                </p>
              </b-col>
              <b-col
                class="my-2"
                cols="12">
                <ul>
                  <li
                    v-for="(item, index) in problemFile"
                    :key="index"
                    class="my-2"
                  >
                    <span class="relative">
                      <label class="remove-file">
                        <span
                          class="pointer"
                          @click="removeFile(index, 'problemFile', `${item && item.file ? 'files' : 'problemFile'}`)">
                          X
                        </span>
                      </label>
                      <a
                        :href="item.url ? item.url : ''"
                        target="_blank">{{ item && item.name ? item.name : '' }}</a>
                    </span>
                  </li>
                </ul>
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="12">
                <b-form-group label="Solution Detail">
                  <div class="my-2">
                    <PreviewSwitch
                      v-model="isSolutionDetailPreview"
                    >
                      Preview
                    </PreviewSwitch>
                  </div>
                  <div
                    v-if="isSolutionDetailPreview"
                    class="box-preview"
                    v-html="knowledge.solutionDetail">
                  </div>
                  <wysiwyg
                    v-else
                    v-model="knowledge.solutionDetail"
                    :rules="{ required: true }"
                    name="Solution Detail"
                    label="Solution Detail"
                    placeholder="Input Solution Detail"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-ripple.400="'rgba(94,88,115)'"
              style="background: #5e5873 !important"
              class="mt-2"
              type="button"
              @click="openFile()"
            >
              Attach Solution Detail
            </b-button>
            <input
              ref="addFiles"
              class="d-none"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*"
              multiple
              @change="onChangeFile($event, 'solutionFile')" />
            <p class="mt-2 text-danger">
              สามารถอัปโหลดได้ไม่เกิน 25MB
            </p>
            <b-row>
              <b-col
                class="my-2"
                cols="12">
                <ul>
                  <li
                    v-for="(item, index) in solutionFile"
                    :key="index"
                    class="my-2"
                  >
                    <span class="relative">
                      <label class="remove-file">
                        <span
                          class="pointer"
                          @click="removeFile(index, 'solutionFile', `${item && item.file ? 'files' : 'solutionFile'}`)">
                          X
                        </span>
                      </label>
                      <a
                        :href="item.url ? item.url : ''"
                        target="_blank">{{ item && item.name ? item.name : '' }}</a>
                    </span>
                  </li>
                </ul>
              </b-col>
            </b-row>
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mt-5 mr-2 btn-submit"
                @click="openDeleteModal()"
              >
                Delete
              </b-button>
            </div>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-5 mr-2 btn-submit"
                type="submit"
              >
                Save Changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="mt-5 mr-5 btn-submit"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TagsField from '@/components/Form/TagsField.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import CategoriesProvider from '@/resources/CategoriesProvider'
import KnowledgeProvider from '@/resources/KnowledgeBaseProvider'
import UploadService from '@/resources/UploadProvider'
import DeleteModal from '@/components/DeleteModal.vue'
import KnowledgeTypeProvider from '@/resources/KnowledgeTypeProvider'
import GroupProvider from '@/resources/GroupProvider'
import PreviewSwitch from '@/components/PreviewSwitch.vue'

const GroupService = new GroupProvider()

const CategoriesService = new CategoriesProvider()
const KnowledgeService = new KnowledgeProvider()
const KnowledgeTypeService = new KnowledgeTypeProvider()

export default {
  components: {
    DeleteModal,
    TextFieldInput,
    SelectField,
    TagsField,
    PreviewSwitch
  },
  data () {
    return {
      isDeleteModalActive: false,
      isProblemDetailPreview: false,
      isSolutionDetailPreview: false,
      deleteItem: {},
      knowledge: {
        code: '',
        subject: '',
        problemDetail: '',
        solutionDetail: '',
        problemFile: '',
        solutionFile: '',
        // categoryId: '',
        visibelGroup: [],
        knowledgeTypeId: null,
        tags: []
      },
      mockData: [
        { label: '#001', value: 0 },
        { label: '#002', value: 1 },
        { label: '#003', value: 2 },
        { label: '#004', value: 3 },
        { label: '#005', value: 4 }
      ],
      files: {
        problemFile: [],
        solutionFile: []
      },
      lvlOneOptions: [],
      lvlTwoOptions: [],
      lvlThreeOptions: [],
      lvlFourOptions: [],
      lvlFiveOptions: [],
      lvlSixOptions: [],
      categories: [],
      categoryLevel: {
        categorySearchLvlOne: '',
        categorySearchLvlTwo: '',
        categorySearchLvlThree: '',
        categorySearchLvlFour: '',
        categorySearchLvlFive: '',
        categorySearchLvlSix: ''
      },
      knowledgeType: [],
      groupService: []
    }
  },
  computed: {
    problemFile () {
      const result = []
      if (this.knowledge.problemFile.length > 0) {
        result.push(...this.knowledge.problemFile)
      }
      if (this.files.problemFile.length > 0) {
        result.push(...this.files.problemFile)
      }
      return result
    },
    solutionFile () {
      const result = []
      if (this.knowledge.solutionFile.length > 0) {
        result.push(...this.knowledge.solutionFile)
      }
      if (this.files.solutionFile.length > 0) {
        result.push(...this.files.solutionFile)
      }
      return result
    }
  },
  async mounted () {
    // await this.getAllCategories()
    this.getKnowledge()
    this.getKnowledgeType()
    this.getGroupService()
  },
  methods: {
    async getAllCategories () {
      try {
        const { data } = await CategoriesService.findAll()
        this.categories = data
        this.lvlOneOptions = data.filter((item) => item?.level === 1)
      } catch (error) {
        console.error(error)
      }
    },
    async getKnowledge () {
      try {
        const { data } = await KnowledgeService.findOne(this.$route.params.id)
        this.knowledge = data
        this.filterParentCategory()
      } catch (error) {
        console.error(error)
      }
    },
    async updateKnowledge () {
      try {
        const problemFile = await this.uploadFile(this.files.problemFile)
        const solutionFile = await this.uploadFile(this.files.solutionFile)
        if (problemFile.length > 0) {
          this.knowledge.problemFile.push(...problemFile)
        }
        if (solutionFile.length > 0) {
          this.knowledge.solutionFile.push(...solutionFile)
        }
        const payload = {
          code: this.knowledge.code,
          subject: this.knowledge.subject,
          problemDetail: this.knowledge.problemDetail,
          solutionDetail: this.knowledge.solutionDetail,
          problemFile: JSON.stringify(this.knowledge.problemFile),
          solutionFile: JSON.stringify(this.knowledge.solutionFile),
          visibelGroup: this.knowledge.visibelGroup,
          knowledgeTypeId: this.knowledge.knowledgeTypeId,
          tags: this.knowledge.tags
        }
        // const categoryId = this.getLastItem(this.categoryLevel)
        // payload.categoryId = categoryId
        await KnowledgeService.updateGroup(this.knowledge.id, payload)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Knowledge Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Knowledge has been updated'
          }
        })
        this.resetForm()
        this.getKnowledge()
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Knowledge Hours has been Failed ${err}`
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await KnowledgeService.deleteGroup(val.ID)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: `👋 Knowledge ${this.deleteItem.Name} has been deleted`
          }
        })
        this.$router.push('/other/knowledge')
      } catch (error) {
        console.error(error)
      }
    },
    async getKnowledgeType () {
      try {
        const { data } = await KnowledgeTypeService.paginate(1, 999999)
        if (data) {
          this.knowledgeType = data
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getGroupService () {
      try {
        const { data } = await GroupService.findAll(1, 999999)
        if (Array.isArray(data)) {
          this.groupService = data?.filter((item) => item?.status === 'active')
        }
      } catch (error) {
        console.error(error)
      }
    },
    selectedItem (parentId, level) {
      const filter = this.findCategory(parentId)
      switch (level) {
      case 1:
        this.lvlTwoOptions = filter
        this.lvlThreeOptions = []
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlTwo = null
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 2:
        this.lvlThreeOptions = filter
        this.lvlFourOptions = []
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlThree = null
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 3:
        this.lvlFourOptions = filter
        this.lvlFiveOptions = []
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFour = null
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 4:
        this.lvlFiveOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlFive = null
        this.categoryLevel.categorySearchLvlSix = null
        break
      case 5:
        this.lvlSixOptions = filter
        this.lvlSixOptions = []
        this.categoryLevel.categorySearchLvlSix = null
        break
      default:
        break
      }
    },
    findCategory (parentId) {
      return this.categories.filter((item) => item.parentId === parentId)
    },
    filterParentCategory () {
      const filter = this.categories.filter((item) => item?.id === this.knowledge?.categoryId)
      const result = [...filter]
      for (let i = 0; i < filter[0]?.level; i++) {
        const fil = this.categories.filter((item) => item?.id === result[i]?.parentId)
        result.push(...fil)
        const filters = this.findCategory(result[i]?.parentId)
        switch (result[i]?.level) {
        case 1:
          this.lvlOneOptions = filters
          break
        case 2:
          this.lvlTwoOptions = filters
          break
        case 3:
          this.lvlThreeOptions = filters
          break
        case 4:
          this.lvlFourOptions = filters
          break
        case 5:
          this.lvlFiveOptions = filters
          break
        case 6:
          this.lvlSixOptions = filters
          break
        default:
          break
        }
      }
      result.forEach((item) => {
        switch (item.level) {
        case 1:
          this.categoryLevel.categorySearchLvlOne = item.id
          break
        case 2:
          this.categoryLevel.categorySearchLvlTwo = item.id
          break
        case 3:
          this.categoryLevel.categorySearchLvlThree = item.id
          break
        case 4:
          this.categoryLevel.categorySearchLvlFour = item.id
          break
        case 5:
          this.categoryLevel.categorySearchLvlFive = item.id
          break
        case 6:
          this.categoryLevel.categorySearchLvlSix = item.id
          break
        default:
          break
        }
      })
    },
    getLastItem (obj) {
      const convertToArray = Object.keys(obj).map((key) => obj[key])
      let result = null
      convertToArray.forEach((item) => {
        if (item && item !== '') {
          result = item
        }
      })
      return result
    },
    resetForm () {
      this.knowledge = {
        code: '',
        subject: '',
        problemDetail: '',
        solutionDetail: '',
        problemFile: [],
        solutionFile: [],
        visibelGroup: [],
        knowledgeTypeId: null
      }
      this.files = {
        problemFile: [],
        solutionFile: []
      }
      this.lvlTwoOptions = []
      this.lvlThreeOptions = []
      this.lvlFourOptions = []
      this.lvlFiveOptions = []
      this.lvlSixOptions = []
      this.categoryLevel = {
        categorySearchLvlOn: '',
        categorySearchLvlTwo: '',
        categorySearchLvlThree: '',
        categorySearchLvlFour: '',
        categorySearchLvlFive: '',
        categorySearchLvlSix: ''
      }
      this.$refs.KnowledgeAddForm.reset()
    },
    openDeleteModal () {
      this.deleteItem = { ID: this.knowledge.id }
      this.isDeleteModalActive = true
    },
    openFile (val) {
      if (val === 'problemFile') {
        this.$refs.addProblemFile.click()
      } else {
        this.$refs.addFiles.click()
      }
    },
    async uploadFile (files) {
      const arr = []
      try {
        if (files && files.length > 0 && typeof files === 'object') {
          for (const item of files) {
            const formData = new FormData()
            formData.append('file', item.file)
            // eslint-disable-next-line no-await-in-loop
            const { data: { data } } = await UploadService.upload(formData)
            arr.push({ name: item.name, url: data.publicUrl })
          }
        }
        return arr
      } catch (error) {
        console.error(error)
        return arr
      }
    },
    onChangeFile (event, key) {
      const { files } = event.target
      let errorMaximumSize = false
      for (let i = 0; i < files.length; i++) {
        if (files[i].size < 26214400) {
          this.files[key].push({
            file: files[i],
            name: files[i].name,
            url: URL.createObjectURL(files[i])
          })
        } else {
          errorMaximumSize = true
        }
      }
      if (errorMaximumSize) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error: File size exceeds the maximum limit',
            icon: 'EditIcon',
            variant: 'danger',
            text: 'maximum size of 10mb.'
          }
        })
      }
    },
    removeFile (index, key, type) {
      if (type === 'problemFile') {
        this.knowledge.problemFile.splice(index, 1)
      }
      if (type === 'solutionFile') {
        this.knowledge.solutionFile.splice(index, 1)
      }
      if (type === 'files') {
        this.files[key].splice(index, 1)
      }
    },
    handleDetailPreview (type) {
      if (type === 'problem') {
        this.isProblemDetailPreview = !this.isProblemDetailPreview
      }

      if (type === 'solution') {
        this.isSolutionDetailPreview = !this.isSolutionDetailPreview
      }
    }
  }
}
</script>

<style scoped>
::v-deep .ql-container.ql-snow > .ql-editor {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.remove-file {
  position: absolute;
  right: -17px;
  bottom: 11px;
}
</style>
